import Mixin from "./../../utils/mixin";
import { baseUrl } from "./../../utils/mixin";
import _ from 'lodash';
export default {
    namespaced: true,
    state: {
        apiPath: "/api/v1/",
        routerLoading: true,
        productQuerries: [],
        unseenProductQuerries: 0,
        userLanguage: localStorage.getItem("shopSelectedLanguage") || 'JP',
        general_settings: {},
        authSettings: {},
        addons: [],
        paymentMethods: [],
        offlinePaymentMethods: []
    },
    getters: {
        appName(state) {
            return state.appName;
        },
        appMetaTitle(state) {
            return state.appMetaTitle;
        },
        appLogo(state) {
            return state.appLogo;
        },
        appCustomerServiceUrl(state) {
            return state.customer_service_url;
        },
        appUrl(state) {
            return state.appUrl.slice(-1) == "/"
                ? state.appUrl.slice(0, state.appUrl.length - 1)
                : state.appUrl;
        },
        demoMode(state) {
            return state.demoMode;
        },
        apiPath(state) {
            return state.apiPath;
        },
        appLanguage(state) {
            return state.appLanguage;
        },
        userLanguage(state) {
            return state.userLanguage || state.appLanguage;
        },
        userLanguageObj(state) {
            console.log(state.allLanguages)
            return state.allLanguages.find(
                (language) => language.code === state.userLanguage
            );
        },
        paymentMethods(state) {
            console.log(state.paymentMethods)
            return state.paymentMethods;
        },
        offlinePaymentMethods(state) {
            console.log(state.offlinePaymentMethods)
            return state.offlinePaymentMethods;
        },
        availableCountries(state) {
            return state.availableCountries;
        },
        allLanguages(state) {
            return state.allLanguages;
        },
        allCurrencies(state) {
            return state.allCurrencies;
        },
        generalSettings(state) {
            console.log('获取general_settings')
            console.log(state.general_settings)
            return state.general_settings;
        },
        banners(state) {
            return state.banners;
        },
        addons(state) {
            console.log(state.addons)
            return state.addons;
        },
        routerLoading(state) {
            return state.routerLoading;
        },
        refundSettings(state) {
            return state.refundSettings;
        },
        getProductQuerries(state) {
            return state.productQuerries;
        },
        getUnseenProductQuerries(state) {
            return state.unseenProductQuerries;
        },
        shopRegistrationMessageTitle(state) {
            return state.shop_registration_message.shop_registration_message_title;
        },
        shopRegistrationMessageContent(state) {
            return state.shop_registration_message.shop_registration_message_content;
        },
        authSettings(state) {
            return state.authSettings
        }
    },
    mutations: {
        setShopSetting(state, shopSetting) {
            _.merge(state, shopSetting)
            // Object.assign(state, shopSetting)
            console.log(state)
        },
		// updateState(state, payload) {
		// 	state = { ...state, ...payload };
		// },
        setProductQuerries(state, data) {
            state.productQuerries = data;
            let unseenData = data.filter((data) => {
                return data.sender_viewed == 0;
            });
            state.unseenProductQuerries = unseenData.length;
        },
        setLanguage(state, lang) {
            if (state.userLanguage !== lang) {
                state.userLanguage = lang;

                localStorage.removeItem("shopSelectedLanguage");
                localStorage.setItem("shopSelectedLanguage", lang);
            }
        },
        removeLanguage(state) {
            state.userLanguage = state.appLanguage;
            localStorage.removeItem("shopSelectedLanguage");
        },
        setRouterLoading(state, status) {
            state.routerLoading = status;
        },
    },
    actions: {
        setLanguage({ commit }, lang) {
            commit("setLanguage", lang);
        },
        removeLanguage({ commit }) {
            commit("removeLanguage");
        },
        async fetchProductQuerries({ commit }) {
            if (this.getters["auth/isAuthenticated"]) {
                const res = await Mixin.methods.call_api(
                    "get",
                    `user/querries`
                );
                if (res.data.success) {
                    commit("setProductQuerries", res.data.data);
                }
            }
        },
        async fetchShopSetting({ commit }) {
            console.log('123213')
            try {
              const response = await axios.get(baseUrl + '/api/v1/setting/main'); // 从API获取数据
              if (response.data.success) {
                console.log(response.data.data.settings)
                commit('setShopSetting', response.data.data.settings);
                const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = response.data.data.settings.appLogo || '/public/assets/img/logo.png';
                document.getElementsByTagName('head')[0].appendChild(link);
                // 设置CSS变量到:root
                document.documentElement.style.setProperty('--primary', response.data.data.settings.base_color || '#007bff');
              }
            } catch (error) {
              console.error('Error fetching shop setting:', error);
            }
        }
    },
};
